<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-06-18 17:34:43
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 15:26:30
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/FlowStrategy/conponents/EditFlowStrategyModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="editFlowStrategyVisible"
    :title="(isBatch ? '批量' : '') + '配置QPS'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="548px"
  >
    <div class="wrapper">
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout" labelAlign="left">
        <!-- 策略类型 -->
        <a-form-model-item
          :rules="[{ required: true, message: '请选择策略类型', trigger: 'change' }]"
          prop="strategyType"
          label="策略类型"
        >
          <a-select disabled allowClear placeholder="请选择策略类型" v-model="query.strategyType">
            <a-select-option :key="item.id" :value="item.id" v-for="item in strategyTypeList">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 供应商 -->
        <a-form-model-item
          :rules="[{ required: true, message: '请选择供应商', trigger: 'change' }]"
          prop="publisherId"
          v-if="+query.strategyType === 1 || +query.strategyType === 2"
          label="供应商"
        >
          <a-select disabled v-model="query.publisherId">
            <a-select-option :key="item.id" :value="item.id" v-for="item in supplierList">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 广告位 -->
        <a-form-model-item
          :rules="[{ required: true, message: '请选择广告位', trigger: 'change' }]"
          prop="placeId"
          v-if="+query.strategyType === 2"
          label="广告位"
        >
          <a-select disabled v-model="query.placeId">
            <a-select-option :key="item.id" :value="item.id" v-for="item in placeList">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 广告平台 -->
        <a-form-model-item
          v-if="!isBatch"
          :rules="[{ required: true, message: '请选择广告平台', trigger: 'change' }]"
          prop="platId"
          label="广告平台"
        >
          <a-select
            disabled
            v-model="query.platId"
          >
            <a-select-option :key="item.id" :value="item.id" v-for="item in platList">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 广告源 -->
        <a-form-model-item
          v-if="!isBatch"
          :rules="[{ required: true, message: '请选择广告源', trigger: 'change' }]"
          prop="dspPlaceId"
          label="广告源"
        >
          <a-select
            disabled
            v-model="query.dspPlaceId"
          >
            <a-select-option :searchId="item.platPlaceId" :key="item.id" :value="item.id" v-for="item in dspList">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 广告源批量数量 -->
        <a-form-model-item
          v-if="isBatch"
          label="广告源"
        >
          <a-input disabled :value="`批量编辑${qpsList.length}条广告源`"></a-input>
        </a-form-model-item>
        <!-- QPS -->
        <a-form-model-item
          :rules="[{ validator: validatorQPS, trigger: 'blur' }]"
          prop="qpsLimit"
          label="QPS"
        >
          <a-input type="number" v-model.number.trim="query.qpsLimit" placeholder="请输入QPS"></a-input>
        </a-form-model-item>
        <!-- 请求比例 -->
        <a-form-model-item
          :rules="[{ validator: validatorRequestRatio, trigger: 'blur' }]"
          prop="requestRatio"
          label="请求比例"
        >
          <a-input type="number" v-model.number.trim="query.requestRatio" placeholder="请输入请求比例">
            <span class="percent-text" slot="suffix">%</span>
          </a-input>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { strategyTypeList } from './list'
import { batchUpdateQPS } from '@/apiForManage/flow/config'
import MixGetList from '@/mixins/getListForManage'
export default {
  mixins: [MixGetList],
  data () {
    return {
      strategyTypeList,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 15 }
      },
      queryTemp: {
        strategyType: undefined,
        publisherId: undefined,
        placeId: undefined,
        platId: undefined,
        dspPlaceId: undefined,
        qpsLimit: undefined,
        requestRatio: undefined
      },
      query: {},
      rules: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isBatch: {
      type: Boolean,
      default: true
    },
    strategyInfo: {
      default: () => ({}),
      type: Object
    },
    qpsList: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    editFlowStrategyVisible: {
      handler (val) {
        if (val) {
          if (!this.isBatch) {
            this.query = { ...this.strategyInfo }
            this.getPlaceList([this.strategyInfo.publisherId])
            this.getDspList([this.strategyInfo.platId])
            this.query.strategyType = '' + this.strategyInfo.strategyType
            this.query.dspPlaceId = '' + this.strategyInfo.dspPlaceId
          } else {
            this.query = {
              ...this.queryTemp,
              strategyType: '' + this.strategyInfo.strategyType,
              publisherId: '' + this.strategyInfo.publisherId,
              placeId: '' + this.strategyInfo.placeId
            }
            this.getPlaceList([this.strategyInfo.publisherId])
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    editFlowStrategyVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  created () {
    this.getSupplierList()
    this.getPlatList()
  },
  methods: {
    validatorQPS (rule, value, callback) {
      if (value < 0) {
        return callback(new Error('QPS必须大于等于0！'))
      } else {
        return callback()
      }
    },
    validatorRequestRatio (rule, value, callback) {
      if (value < 0) {
        return callback(new Error('请求比例必须大于等于0！'))
      } else if (value > 100) {
        return callback(new Error('请求比例必须小于等于100！'))
      } else {
        return callback()
      }
    },
    handleCancel () {
      this.editFlowStrategyVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // if (!this.query.qpsLimit && !this.query.requestRatio) {
          //   this.$message.error('请输入QPS或请求比例！')
          //   return
          // }
          const query = {
            strategyType: this.strategyInfo.strategyType,
            qpsLimit: this.query.qpsLimit,
            requestRatio: this.query.requestRatio,
            idList: []
          }
          if (!this.isBatch) {
            query.idList = [
              {
                publisherId: this.query.publisherId,
                placeId: this.query.placeId,
                dspPlaceId: this.query.dspPlaceId
              }
            ]
          } else {
            query.idList = this.qpsList.map((item) => {
              return {
                publisherId: this.strategyInfo.publisherId,
                placeId: this.strategyInfo.placeId,
                dspPlaceId: item
              }
            })
          }
          const resp = await batchUpdateQPS(query)
          if (resp.code === 200) {
            this.$message.success('修改成功！')
            this.editFlowStrategyVisible = false
            this.$emit('changeStrategy')
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  height: 400px;
  overflow-y: auto;
}
</style>
