<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-05-23 14:30:31
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-19 14:39:38
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/AdplaceConfig/components/BatchCopySourceModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="batchCopySourceVisible"
    title="批量复制"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="618px"
  >
    <a-form-model ref="ruleForm" class="form" :model="query" :rules="rules" v-bind="layout">
      <a-form-model-item
        label="当前位置"
      >
        <a-input disabled :value="`${type === '1' ? '供应商' : '广告位'}`"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="已选择预算条数"
      >
        <a-input disabled :value="`${idList.length}条`"></a-input>
      </a-form-model-item>
      <a-form-model-item
        prop="type"
        label="复制到"
      >
        <a-radio-group v-model="query.copyType" @change="changeCopyType">
          <a-radio value="1">
            供应商
          </a-radio>
          <a-radio value="2">
            广告位
          </a-radio>
        </a-radio-group>
        <div v-if="query.copyType !== query.type" class="hint-wrapper"><div class="hint"><a-icon class="icon" type="exclamation-circle" />流量类型不匹配，仅能复制广告源参数，不能复制利润策略</div></div>
      </a-form-model-item>
      <a-form-model-item
        prop="publisherIdList"
        :rules="[{ required: true, message: '请选择供应商', trigger: 'change' }]"
        label="供应商"
      >
        <m-select-more
          style="width: 100%"
          v-model="query.publisherIdList"
          :allData="compSupplierList"
          :searchById="true"
          :hasIcon="false"
          :showId="true"
          width="450px"
          label="供应商"
          :showLabel="false"
          @change="handleChangeSupplier"
        />
      </a-form-model-item>
      <a-form-model-item
        v-if="query.copyType === '2'"
        prop="placeIdList"
        :rules="[{ required: true, message: '请选择广告位', trigger: 'change' }]"
        label="广告位"
      >
        <m-select-more
          style="width: 100%"
          v-model="query.placeIdList"
          :allData="compPlaceList"
          :searchById="true"
          :hasIcon="false"
          :showId="true"
          width="450px"
          label="广告位"
          :showLabel="false"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import MixGetList from '@/mixins/getListForManage'
import { batchCopySource } from '@/apiForManage/flow/config'
export default {
  mixins: [MixGetList],
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
      },
      query: {
        ids: [],
        copyType: '1',
        publisherIdList: [],
        placeIdList: [],
        type: undefined
      },
      rules: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    idList: {
      default: () => ([]),
      type: Array
    },
    supplierId: {
      default: undefined,
      type: String
    },
    placeId: {
      default: undefined,
      type: String
    },
    type: {
      default: undefined,
      type: String
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          this.query.ids = this.idList
          this.query.publisherIdList = []
          this.query.placeIdList = []
          this.query.copyType = '1'
          this.query.type = this.type
          this.$nextTick(() => {
            this.$refs.ruleForm.clearValidate()
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    compSupplierList () {
      return this.supplierList
    },
    compPlaceList () {
      return this.placeList.filter((item) => item.id !== this.placeId)
    },
    batchCopySourceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  created () {
    this.getSupplierList()
  },
  methods: {
    // 目标类型修改回调
    changeCopyType () {
      this.query.publisherIdList = []
      this.query.placeIdList = []
    },
    // 供应商修改回调
    async handleChangeSupplier () {
      this.query.placeIdList = []
      await this.getPlaceList(this.query.publisherIdList)
    },
    handleCancel () {
      this.batchCopySourceVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          batchCopySource(this.query).then((res) => {
            if (res.code === 200) {
              this.$message.success('批量复制成功')
              this.batchCopySourceVisible = false
              this.$emit('copySuccess')
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.form{
  padding: 0 40px 0 60px;
  .hint{
    white-space: nowrap;
    font-size: 12px;
    // transform: scale(0.91) translateX(-30px);
    line-height: 12px;
    color: @compDisabledColor;
    margin-top: 6px;
    .icon{
      color: #ff2d55;
      height: 12px;
      width: 12px;
      // vertical-align: middle;
      margin-right: 6px;
    }
  }
}
</style>
