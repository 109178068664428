<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-23 18:54:11
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-21 15:38:41
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/AdplaceConfig/Header.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="place-config-head">
    <div class="left">
      <span class="place-config-head-title" :title="configType">流量类型：{{ getText(configType) }}</span>
      <span class="place-config-head-title" v-if="configType === '广告位'" :title="supplierName">供应商：{{ getText(supplierName) }}</span>
      <span class="place-config-head-title" v-else :title="supplierName">供应商：
        <a-select class="select" v-model="publisherId" @change="handleChangeSupplier">
          <a-select-option v-for="item in supplierList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </span>
      <span class="place-config-head-title" :title="placeName" v-if="placeName">广告位：
        <a-select class="select" v-model="placeId" @change="handleChangePlace">
          <a-select-option v-for="item in placeList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </span>
      <span class="place-config-head-title">已配置广告源：{{ sourceCount }}条</span>
    </div>
    <span class="primary-text" @click="handleSkipToAdx">查看数据详情</span>
  </div>
</template>

<script>
import MixGetList from '@/mixins/getListForManage'
export default {
  mixins: [MixGetList],
  data () {
    return {
      placeName: '',
      supplierName: '',
      configType: undefined,
      publisherId: undefined,
      placeId: undefined
    }
  },
  props: {
    sourceCount: {
      type: Number,
      default: 0
    }
  },
  created () {
    this.getSupplierList()
    const { query = {} } = this.$route
    const { placeName, supplierName, supplierId, placeId } = query
    this.placeName = placeName
    this.supplierName = supplierName
    this.placeId = placeId
    if (placeId) {
      this.getPlaceList([supplierId])
    }
    this.publisherId = supplierId
    this.configType = placeName ? '广告位' : '供应商'
  },
  methods: {
    async handleChangeSupplier () {
      this.$emit('changeSupplier', this.publisherId)
    },
    async handleChangePlace () {
      this.$emit('changePlace', this.placeId)
    },
    getText (text) {
      return text.length > 10 ? `${text.slice(0, 10)}...` : text
    },
    handleSkipToAdx () {
      const url = this.$router.resolve({
        name: 'synthreport',
        query: {
          publisherId: this.publisherId,
          placeId: this.placeId,
          hasSupplier: 1
        }
      })
      window.open(url.href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.place-config-head{
  padding: 24px;
  display: flex;
  justify-content: space-between;
  color: #5a607f;
  align-items: center;
  .left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .place-config-head-title{
      margin-right: 41px;
      position: relative;
      display: flex;
      align-items: center;
      .select{
        width: 180px;
      }
      &::after{
        content: '';
        position: absolute;
        right: -20px;
        height: 20px;
        width: 1px;
        background-color: #ecedef;
      }
      &:last-child{
        &::after{
          display: none;
        }
      }
    }
  }
  .primary-text{
    cursor: pointer;
  }
}
</style>
